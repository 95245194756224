import React, { useState } from 'react';
import EventForm from '../event-form/event-form';
import CheckResult from '../check-result/check-result';
import { Screens } from '../../const';

const ServiceCheck = () => {
	const [screen, setScreen] = useState('');

	const finalScreen =
		Screens.REQUIRED === screen ? (
			<CheckResult isRequired={true} setScreen={setScreen} />
		) : Screens.NOT_REQUIRED === screen ? (
			<CheckResult isRequired={false} setScreen={setScreen} />
		) : (
			<EventForm setScreen={setScreen} />
		);

	return (
		<div className="container service-check">
			<h1 className="service-check__title">
				Проверка автомобиля на&nbsp;сервисные мероприятия
			</h1>
			<p className="service-check__text">
				Введите в&nbsp;поле свой VIN, система сразу&nbsp;же покажет,
				действуют&nbsp;ли на&nbsp;ваш автомобиль сервисные мероприятия.
				Если&nbsp;да, то&nbsp;Вам необходимо связаться с&nbsp;любым официальным
				дилерским центром Volkswagen и&nbsp;записаться для проведения
				необходимых работ.
			</p>
			{finalScreen}
		</div>
	);
};

export default ServiceCheck;
