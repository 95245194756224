
export const codebase = [
 {
  name: "M700",
  date: "03/21/2006",
  b: "V",
  description: "A-see 20M1 (account instruction m700 0",
  column5: null,
  column6: null,
  column7: null,
  selected: 36,
  called: 32,
  percent: 88
 },
 {
  name: "N400",
  date: "11/27/2006",
  b: "V",
  description: "A-Campaign N4: Replace Brake Light Swi",
  column5: null,
  column6: null,
  column7: null,
  selected: 180,
  called: 140,
  percent: 77
 },
 {
  name: "UF00",
  date: "10/25/2012",
  b: "V",
  description: "A-REMOVE B-PILLAR TRIM COVER MAT",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "UU00",
  date: "07/07/2005",
  b: "V",
  description: "A-INSPECT/REPLACE FUEL SUPPLY MODULE",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "VX00",
  date: "03/15/2005",
  b: "V",
  description: "A-REPLACE EMERGENCY LIGHT SWITCH (HAZA",
  column5: null,
  column6: null,
  column7: null,
  selected: 31,
  called: 21,
  percent: 67
 },
 {
  name: "01C5",
  date: "01/22/2019",
  b: "V",
  description: "A-Buy-back of analysis vehicles",
  column5: null,
  column6: null,
  column7: null,
  selected: 67,
  called: 37,
  percent: 55
 },
 {
  name: "01C6",
  date: "03/20/2019",
  b: "V",
  description: "A-Modify analysis vehicle",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 1,
  percent: 33
 },
 {
  name: "01D7",
  date: "08/04/2020",
  b: "V",
  description: "A-Buyback of analysis vehicle",
  column5: null,
  column6: null,
  column7: null,
  selected: 8,
  called: 2,
  percent: 25
 },
 {
  name: "10H1",
  date: "02/18/2022",
  b: "V",
  description: "A-SCHALLSCHUTZWANNE",
  column5: null,
  column6: null,
  column7: null,
  selected: 19354,
  called: 2712,
  percent: 14
 },
 {
  name: "13C3",
  date: "04/04/2006",
  b: "V",
  description: "A-Two-mass flywheel",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 1,
  percent: 50
 },
 {
  name: "13C5",
  date: "09/12/2005",
  b: "V",
  description: "A-Two-mass flywheel",
  column5: null,
  column6: null,
  column7: null,
  selected: 31,
  called: 26,
  percent: 83
 },
 {
  name: "13D2",
  date: "06/26/2006",
  b: "V",
  description: "A-Two-mass flywheel",
  column5: null,
  column6: null,
  column7: null,
  selected: 12,
  called: 11,
  percent: 91
 },
 {
  name: "15H2",
  date: "08/20/2019",
  b: "V",
  description: "A-Replace camshaft adjuster",
  column5: null,
  column6: null,
  column7: null,
  selected: 144,
  called: 34,
  percent: 23
 },
 {
  name: "20I4",
  date: "03/16/2011",
  b: "V",
  description: "A-Under Hood Fuel Supply Line",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 3,
  percent: 60
 },
 {
  name: "20K9",
  date: "12/10/2004",
  b: "V",
  description: "A-Tandem pump",
  column5: null,
  column6: null,
  column7: null,
  selected: 159,
  called: 107,
  percent: 67
 },
 {
  name: "20T3",
  date: "10/01/2012",
  b: "V",
  description: "A-Gas tanks",
  column5: null,
  column6: null,
  column7: null,
  selected: 38,
  called: 33,
  percent: 86
 },
 {
  name: "20U7",
  date: "10/27/2014",
  b: "V",
  description: "A-Diesel fuel filter",
  column5: null,
  column6: null,
  column7: null,
  selected: 15,
  called: 8,
  percent: 53
 },
 {
  name: "20X4",
  date: "07/01/2016",
  b: "V",
  description: "A-Gas tanks",
  column5: null,
  column6: null,
  column7: null,
  selected: 1039,
  called: 993,
  percent: 95
 },
 {
  name: "20X7",
  date: "06/03/2016",
  b: "V",
  description: "A-Gas tanks",
  column5: null,
  column6: null,
  column7: null,
  selected: 38,
  called: 32,
  percent: 84
 },
 {
  name: "20Y6",
  date: "11/04/2016",
  b: "V",
  description: "A-Suction-jet pump",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "20Y8",
  date: "02/22/2017",
  b: "V",
  description: "A-Gas tanks",
  column5: null,
  column6: null,
  column7: null,
  selected: 222,
  called: 212,
  percent: 95
 },
 {
  name: "23J9",
  date: "12/15/2011",
  b: "V",
  description: "A-HIGH PRESSURE FUEL LINE",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "23K2",
  date: "12/15/2011",
  b: "V",
  description: "A-Balance weight for high pressure pip",
  column5: null,
  column6: null,
  column7: null,
  selected: 511,
  called: 494,
  percent: 96
 },
 {
  name: "23Q2",
  date: "12/31/1949",
  b: "V",
  description: "A-Recall campaign for diesel exhaust g",
  column5: null,
  column6: null,
  column7: null,
  selected: 35142,
  called: 13069,
  percent: 37
 },
 {
  name: "23R1",
  date: "04/22/2016",
  b: "V",
  description: "A-Reprogram engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "23R7",
  date: "01/25/2016",
  b: "V",
  description: "A-Engine control unit (NOx)",
  column5: null,
  column6: null,
  column7: null,
  selected: 33552,
  called: 11399,
  percent: 33
 },
 {
  name: "23T3",
  date: "03/29/2017",
  b: "V",
  description: "A-RECALL - Connector Seal",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "23W5",
  date: "07/26/2017",
  b: "V",
  description: "A-Heat shield",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "23Y3",
  date: "02/16/2018",
  b: "V",
  description: "A-Update engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "23Z1",
  date: "05/24/2018",
  b: "V",
  description: "A-Adjustments following campaign 23R7",
  column5: null,
  column6: null,
  column7: null,
  selected: 16,
  called: 1,
  percent: 6
 },
 {
  name: "24BK",
  date: "03/16/2015",
  b: "V",
  description: "A-Replacement of fuel distributor",
  column5: null,
  column6: null,
  column7: null,
  selected: 31,
  called: 29,
  percent: 93
 },
 {
  name: "24BL",
  date: "02/19/2015",
  b: "V",
  description: "A-Fuel rail",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 1,
  percent: 50
 },
 {
  name: "24FK",
  date: "02/24/2022",
  b: "V",
  description: "A-Engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 369,
  called: 75,
  percent: 20
 },
 {
  name: "24M9",
  date: "05/30/2008",
  b: "V",
  description: "A-Engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 25,
  called: 25,
  percent: 100
 },
 {
  name: 2600000,
  date: "06/13/2008",
  b: "V",
  description: "A-Safety Recall R5: Install Underbody",
  column5: null,
  column6: null,
  column7: null,
  selected: 266,
  called: 179,
  percent: 67
 },
 {
  name: "26M4",
  date: "02/24/2022",
  b: "V",
  description: "A-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: "26N6",
  date: "04/09/2022",
  b: "V",
  description: "A-Catalytic converter",
  column5: null,
  column6: null,
  column7: null,
  selected: 553,
  called: 2,
  percent: 0
 },
 {
  name: "27F9",
  date: "03/02/2018",
  b: "V",
  description: "A-Solenoid switch",
  column5: null,
  column6: null,
  column7: null,
  selected: 116,
  called: 36,
  percent: 31
 },
 {
  name: "28F6",
  date: "01/14/2011",
  b: "V",
  description: "A-durchfьhren Glow Plugs",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "28H1",
  date: "05/10/2016",
  b: "V",
  description: "A-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: 3700000000,
  date: "10/16/2009",
  b: "V",
  description: "A-Control unit of direct shift gearbox",
  column5: null,
  column6: null,
  column7: null,
  selected: 846,
  called: 834,
  percent: 98
 },
 {
  name: "38C6",
  date: "05/20/2020",
  b: "V",
  description: "A-Gearbox oil pipe",
  column5: null,
  column6: null,
  column7: null,
  selected: 1099,
  called: 1080,
  percent: 98
 },
 {
  name: "40I1",
  date: "03/24/2010",
  b: "V",
  description: "A-ACHSLENKERVERSCHRAUBUNG (RUS)",
  column5: null,
  column6: null,
  column7: null,
  selected: 780,
  called: 771,
  percent: 98
 },
 {
  name: "40J2",
  date: "06/24/2009",
  b: "V",
  description: "A-SAFETY CAMPAIGN S4 REPLACE DRIVE SHA",
  column5: null,
  column6: null,
  column7: null,
  selected: 9,
  called: 9,
  percent: 100
 },
 {
  name: "40O3",
  date: "02/27/2019",
  b: "V",
  description: "A-Shock absorber fork, Touareg",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "42C7",
  date: "07/13/2000",
  b: "V",
  description: "A-Replacement of rear axle ball joints",
  column5: null,
  column6: null,
  column7: null,
  selected: 74,
  called: 26,
  percent: 35
 },
 {
  name: "42G8",
  date: "05/18/2015",
  b: "V",
  description: "A-Service installation of inlay plates",
  column5: null,
  column6: null,
  column7: null,
  selected: 22033,
  called: 13995,
  percent: 63
 },
 {
  name: "42I2",
  date: "11/17/2017",
  b: "V",
  description: "A-Rear wheel bearing housing",
  column5: null,
  column6: null,
  column7: null,
  selected: 11,
  called: 11,
  percent: 100
 },
 {
  name: "42I7",
  date: "06/18/2018",
  b: "V",
  description: "A-StoЯdдmpfer hinten Tiguan LWB",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "42J3",
  date: "04/04/2019",
  b: "V",
  description: "A-Teramont, rear coil springs",
  column5: null,
  column6: null,
  column7: null,
  selected: 371,
  called: 370,
  percent: 99
 },
 {
  name: "42K9",
  date: "06/29/2021",
  b: "V",
  description: "A-Transverse link bolted connection",
  column5: null,
  column6: null,
  column7: null,
  selected: 476,
  called: 412,
  percent: 86
 },
 {
  name: "45B1",
  date: "02/28/2002",
  b: "V",
  description: "A-Replacement of ABS control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 1007,
  called: 525,
  percent: 52
 },
 {
  name: "45F2",
  date: "06/20/2017",
  b: "V",
  description: "A-ABS/ESP control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 1125,
  called: 359,
  percent: 31
 },
 {
  name: "45I4",
  date: "05/15/2020",
  b: "V",
  description: "A-Steuergerдt EPB RDK+",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "46G4",
  date: "03/26/2016",
  b: "V",
  description: "A-Pedal cluster circlip",
  column5: null,
  column6: null,
  column7: null,
  selected: 44128,
  called: 36568,
  percent: 82
 },
 {
  name: "46H6",
  date: "09/14/2018",
  b: "V",
  description: "A-Bolted connection of brake calliper",
  column5: null,
  column6: null,
  column7: null,
  selected: 142,
  called: 141,
  percent: 99
 },
 {
  name: "46I6",
  date: "12/26/2020",
  b: "V",
  description: "A-Brake pedal welding",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "47H2",
  date: "05/24/2002",
  b: "V",
  description: "A-Vacuum pipe on brake servo",
  column5: null,
  column6: null,
  column7: null,
  selected: 201,
  called: 63,
  percent: 31
 },
 {
  name: "47J2",
  date: "07/03/2003",
  b: "V",
  description: "A-Vacuum pipe on brake servo",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 2,
  percent: 40
 },
 {
  name: "47O3",
  date: "07/09/2018",
  b: "V",
  description: "A-Bleed hydraulic brake system",
  column5: null,
  column6: null,
  column7: null,
  selected: 286,
  called: 286,
  percent: 100
 },
 {
  name: "47R3",
  date: "09/20/2021",
  b: "V",
  description: "A-Retighten and check electromechanica",
  column5: null,
  column6: null,
  column7: null,
  selected: 10,
  called: 8,
  percent: 80
 },
 {
  name: "48A2",
  date: "11/23/2005",
  b: "V",
  description: "A-Attachment of track rod ends/wheel b",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: 4800000,
  date: "09/01/1999",
  b: "V",
  description: "A-Replace Track rod ends",
  column5: null,
  column6: null,
  column7: null,
  selected: 2554,
  called: 1427,
  percent: 55
 },
 {
  name: "48J1",
  date: "10/31/2008",
  b: "V",
  description: "A-Steering rack",
  column5: null,
  column6: null,
  column7: null,
  selected: 25,
  called: 25,
  percent: 100
 },
 {
  name: "48P1",
  date: "03/18/2020",
  b: "V",
  description: "A-Steering shaft",
  column5: null,
  column6: null,
  column7: null,
  selected: 24,
  called: 23,
  percent: 95
 },
 {
  name: "50D9",
  date: "12/27/2017",
  b: "V",
  description: "A-Towing eye",
  column5: null,
  column6: null,
  column7: null,
  selected: 100666,
  called: 54877,
  percent: 54
 },
 {
  name: "53B8",
  date: "02/15/2016",
  b: "V",
  description: "A-Water drain valve",
  column5: null,
  column6: null,
  column7: null,
  selected: 53,
  called: 40,
  percent: 75
 },
 {
  name: "55D6",
  date: "12/07/2006",
  b: "V",
  description: "A-Bonnet lock",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "55L6",
  date: "11/09/2021",
  b: "V",
  description: "A-Drive unit for rear lid",
  column5: null,
  column6: null,
  column7: null,
  selected: 282,
  called: 169,
  percent: 59
 },
 {
  name: "58C9",
  date: "06/23/2016",
  b: "V",
  description: "A-Check manual child lock",
  column5: null,
  column6: null,
  column7: null,
  selected: 398,
  called: 364,
  percent: 91
 },
 {
  name: "60C2",
  date: "07/05/2016",
  b: "V",
  description: "A-Repair solar roof",
  column5: null,
  column6: null,
  column7: null,
  selected: 78,
  called: 26,
  percent: 33
 },
 {
  name: "60D3",
  date: "12/24/2018",
  b: "V",
  description: "A-Ambientebeleuchtung PSD VFG RUS",
  column5: null,
  column6: null,
  column7: null,
  selected: 4320,
  called: 4062,
  percent: 94
 },
 {
  name: "60D6",
  date: "03/18/2019",
  b: "V",
  description: "A-Sun roof trim",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: 6000000000,
  date: "10/09/2020",
  b: "V",
  description: "A-Sun roof repair",
  column5: null,
  column6: null,
  column7: null,
  selected: 4,
  called: 1,
  percent: 25
 },
 {
  name: "66D6",
  date: "07/06/2009",
  b: "V",
  description: "A-Rear spoiler",
  column5: null,
  column6: null,
  column7: null,
  selected: 21374,
  called: 18859,
  percent: 88
 },
 {
  name: "66J9",
  date: "01/09/2019",
  b: "V",
  description: "A-Rear spoiler",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "66L5",
  date: "05/20/2020",
  b: "V",
  description: "A-Fix roof spoiler in position",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "66M4",
  date: "10/09/2020",
  b: "V",
  description: "A-Door cover",
  column5: null,
  column6: null,
  column7: null,
  selected: 13,
  called: 10,
  percent: 76
 },
 {
  name: "66M8",
  date: "01/05/2022",
  b: "V",
  description: "A-ЬBERPRЬFUNG ANHДNGEVORRICHTUNG",
  column5: null,
  column6: null,
  column7: null,
  selected: 444,
  called: 55,
  percent: 12
 },
 {
  name: "67A1",
  date: "11/03/2008",
  b: "V",
  description: "A-Manual control",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 1,
  percent: 50
 },
 {
  name: "69AR",
  date: "07/06/2020",
  b: "V",
  description: "A-Driver side front airbag (NADI)",
  column5: null,
  column6: null,
  column7: null,
  selected: 8,
  called: 0,
  percent: 0
 },
 {
  name: "69B7",
  date: "04/12/1999",
  b: "V",
  description: "A-Deactivation/ activation of passenge",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: "69B8",
  date: "05/12/1999",
  b: "V",
  description: "A-Replace airbag control unit 605",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "69B9",
  date: "04/14/1999",
  b: "V",
  description: "A-Check rear pushbutton locks",
  column5: null,
  column6: null,
  column7: null,
  selected: 163,
  called: 19,
  percent: 11
 },
 {
  name: "69CL",
  date: "05/20/2022",
  b: "V",
  description: "A-Airbag control unit securing pin",
  column5: null,
  column6: null,
  column7: null,
  selected: 12,
  called: 0,
  percent: 0
 },
 {
  name: "69CN",
  date: "09/15/2021",
  b: "V",
  description: "A-Steuergerдte fьr Airbags",
  column5: null,
  column6: null,
  column7: null,
  selected: 134,
  called: 98,
  percent: 73
 },
 {
  name: "69CO",
  date: "11/12/2021",
  b: "V",
  description: "A-Driver side front airbag (NADI)",
  column5: null,
  column6: null,
  column7: null,
  selected: 7,
  called: 0,
  percent: 0
 },
 {
  name: "69D5",
  date: "01/19/2001",
  b: "V",
  description: "A-Replace passenger airbag unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 10,
  called: 4,
  percent: 40
 },
 {
  name: "69F5",
  date: "07/01/2004",
  b: "V",
  description: "A-Rear belt lock",
  column5: null,
  column6: null,
  column7: null,
  selected: 1689,
  called: 1445,
  percent: 85
 },
 {
  name: "69H3",
  date: "03/31/2009",
  b: "V",
  description: "A-Belt lock adjustment on passenger si",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: "69L9",
  date: "04/18/2016",
  b: "V",
  description: "A-Side airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 611,
  called: 528,
  percent: 86
 },
 {
  name: "69P4",
  date: "04/03/2017",
  b: "V",
  description: "A-Airbag igniter, seat belt with belt",
  column5: null,
  column6: null,
  column7: null,
  selected: 73,
  called: 69,
  percent: 94
 },
 {
  name: "69W9",
  date: "07/20/2018",
  b: "V",
  description: "A-Passenger airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 76,
  called: 75,
  percent: 98
 },
 {
  name: "69X1",
  date: "07/06/2018",
  b: "V",
  description: "A-Supplement for child seats",
  column5: null,
  column6: null,
  column7: null,
  selected: 1170,
  called: 1162,
  percent: 99
 },
 {
  name: "69X3",
  date: "01/31/2019",
  b: "V",
  description: "A-Gas generator of driver airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 38,
  called: 0,
  percent: 0
 },
 {
  name: "69Y2",
  date: "12/07/2018",
  b: "V",
  description: "A-Rear right belt buckle",
  column5: null,
  column6: null,
  column7: null,
  selected: 178,
  called: 172,
  percent: 96
 },
 {
  name: "69Z5",
  date: "11/18/2019",
  b: "V",
  description: "A-Reprogram airbag control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 64,
  called: 33,
  percent: 51
 },
 {
  name: "72D1",
  date: "02/16/2004",
  b: "V",
  description: "A-Anti-chafing protection of parking b",
  column5: null,
  column6: null,
  column7: null,
  selected: 839,
  called: 689,
  percent: 82
 },
 {
  name: "72H5",
  date: "05/18/2018",
  b: "V",
  description: "A-Head restraint guide",
  column5: null,
  column6: null,
  column7: null,
  selected: 29,
  called: 27,
  percent: 93
 },
 {
  name: "72H7",
  date: "08/16/2018",
  b: "V",
  description: "A-Head restraint guide",
  column5: null,
  column6: null,
  column7: null,
  selected: 19,
  called: 17,
  percent: 89
 },
 {
  name: "72H8",
  date: "12/19/2018",
  b: "V",
  description: "A-Check backrest of rear seat",
  column5: null,
  column6: null,
  column7: null,
  selected: 24,
  called: 24,
  percent: 100
 },
 {
  name: "72K8",
  date: "09/18/2020",
  b: "V",
  description: "A-Front seat backrest",
  column5: null,
  column6: null,
  column7: null,
  selected: 25,
  called: 24,
  percent: 96
 },
 {
  name: "74B6",
  date: "03/06/2007",
  b: "V",
  description: "A-tension wire of backrest cover",
  column5: null,
  column6: null,
  column7: null,
  selected: 382,
  called: 260,
  percent: 68
 },
 {
  name: "87B3",
  date: "01/26/2007",
  b: "V",
  description: "A-Heating element of additional air he",
  column5: null,
  column6: null,
  column7: null,
  selected: 14,
  called: 14,
  percent: 100
 },
 {
  name: "87B4",
  date: "01/17/2008",
  b: "V",
  description: "A-Heating element of additional air he",
  column5: null,
  column6: null,
  column7: null,
  selected: 43,
  called: 40,
  percent: 93
 },
 {
  name: "87B5",
  date: "06/30/2008",
  b: "V",
  description: "A-Heating element of auxiliary heater",
  column5: null,
  column6: null,
  column7: null,
  selected: 27,
  called: 27,
  percent: 100
 },
 {
  name: "92A3",
  date: "03/12/2002",
  b: "V",
  description: "A-Wiper motor replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 37,
  called: 16,
  percent: 43
 },
 {
  name: "92B6",
  date: "12/07/2006",
  b: "V",
  description: "A-Wiper motor cap",
  column5: null,
  column6: null,
  column7: null,
  selected: 2972,
  called: 2569,
  percent: 86
 },
 {
  name: "92B9",
  date: "08/28/2007",
  b: "V",
  description: "A-Wasserbehдlter (N8)",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 2,
  percent: 66
 },
 {
  name: "97BP",
  date: "02/18/2016",
  b: "V",
  description: "A-Check connector",
  column5: null,
  column6: null,
  column7: null,
  selected: 11,
  called: 11,
  percent: 100
 },
 {
  name: "97BT",
  date: "03/30/2016",
  b: "V",
  description: "A-Panorama sun roof",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "97CH",
  date: "01/23/2017",
  b: "V",
  description: "A-Onboard supply control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 577,
  called: 558,
  percent: 96
 },
 {
  name: "97F9",
  date: "03/03/1999",
  b: "V",
  description: "A-Contact change of central locking co",
  column5: null,
  column6: null,
  column7: null,
  selected: 94,
  called: 3,
  percent: 3
 },
 {
  name: "97J5",
  date: "11/13/2003",
  b: "V",
  description: "A-ROUTING OF SEAT WIRING LOOM",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 5,
  percent: 100
 },
 {
  name: "97S8",
  date: "10/15/2010",
  b: "V",
  description: "A-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "97V3",
  date: "04/08/2011",
  b: "V",
  description: "A-Sicherungskasten (ServiceNet)",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 3,
  percent: 100
 },
 {
  name: "97W5",
  date: "11/14/2011",
  b: "V",
  description: "A-Airbag wiring loom",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: 10000,
  date: "08/04/2020",
  b: "N",
  description: "A-Buyback of analysis vehicle",
  column5: null,
  column6: null,
  column7: null,
  selected: 12,
  called: 1,
  percent: 8
 },
 {
  name: "20K9",
  date: "12/10/2004",
  b: "N",
  description: "A-Tandem pump",
  column5: null,
  column6: null,
  column7: null,
  selected: 159,
  called: 107,
  percent: 67
 },
 {
  name: "20T6",
  date: "11/25/2013",
  b: "N",
  description: "A-Fuel return line",
  column5: null,
  column6: null,
  column7: null,
  selected: 9174,
  called: 7979,
  percent: 86
 },
 {
  name: "20X4",
  date: "07/01/2016",
  b: "N",
  description: "A-Gas tanks",
  column5: null,
  column6: null,
  column7: null,
  selected: 1039,
  called: 993,
  percent: 95
 },
 {
  name: "20Y8",
  date: "02/22/2017",
  b: "N",
  description: "A-Gas tanks",
  column5: null,
  column6: null,
  column7: null,
  selected: 222,
  called: 212,
  percent: 95
 },
 {
  name: "23K2",
  date: "12/15/2011",
  b: "N",
  description: "A-Balance weight for high pressure pip",
  column5: null,
  column6: null,
  column7: null,
  selected: 511,
  called: 494,
  percent: 96
 },
 {
  name: "23Q2",
  date: "12/31/1949",
  b: "N",
  description: "A-Recall campaign for diesel exhaust g",
  column5: null,
  column6: null,
  column7: null,
  selected: 35142,
  called: 13069,
  percent: 37
 },
 {
  name: "23R7",
  date: "01/25/2016",
  b: "N",
  description: "A-Engine control unit (NOx)",
  column5: null,
  column6: null,
  column7: null,
  selected: 33552,
  called: 11399,
  percent: 33
 },
 {
  name: 3700000000,
  date: "10/16/2009",
  b: "N",
  description: "A-Control unit of direct shift gearbox",
  column5: null,
  column6: null,
  column7: null,
  selected: 846,
  called: 834,
  percent: 98
 },
 {
  name: "37L8",
  date: "11/07/2019",
  b: "N",
  description: "A-Update gearbox/engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 35,
  called: 9,
  percent: 25
 },
 {
  name: "39B2",
  date: "02/12/2001",
  b: "N",
  description: "A-Propshaft replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 1,
  percent: 33
 },
 {
  name: 390000,
  date: "01/20/2015",
  b: "N",
  description: "A-Propshaft pan",
  column5: null,
  column6: null,
  column7: null,
  selected: 1363,
  called: 985,
  percent: 72
 },
 {
  name: "39F1",
  date: "06/12/2020",
  b: "N",
  description: "A-Top speed limitation",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "42D4",
  date: "12/07/2006",
  b: "N",
  description: "A-Blattfeder hinten",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: 420000,
  date: "06/18/2007",
  b: "N",
  description: "A-Spring clamp",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: 42000000000,
  date: "12/01/2008",
  b: "N",
  description: "A-Rear drive shaft",
  column5: null,
  column6: null,
  column7: null,
  selected: 14,
  called: 13,
  percent: 92
 },
 {
  name: "42F1",
  date: "12/01/2008",
  b: "N",
  description: "A-Leaf spring",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 3,
  percent: 100
 },
 {
  name: "44R9",
  date: "12/30/2021",
  b: "N",
  description: "A-Continental tyres replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: "45F2",
  date: "06/20/2017",
  b: "N",
  description: "A-ABS/ESP control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 1125,
  called: 359,
  percent: 31
 },
 {
  name: 4600000,
  date: "04/06/2006",
  b: "N",
  description: "A-Handbrake lever replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 134,
  called: 53,
  percent: 39
 },
 {
  name: "47M2",
  date: "07/03/2015",
  b: "N",
  description: "A-Bolted connection of brake calliper",
  column5: null,
  column6: null,
  column7: null,
  selected: 4,
  called: 4,
  percent: 100
 },
 {
  name: "48G5",
  date: "03/11/2004",
  b: "N",
  description: "A-Steering rack attachment",
  column5: null,
  column6: null,
  column7: null,
  selected: 299,
  called: 212,
  percent: 70
 },
 {
  name: "48H2",
  date: "03/20/2006",
  b: "N",
  description: "A-Replacement of middle track rod",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 0,
  percent: 0
 },
 {
  name: "48O1",
  date: "12/11/2017",
  b: "N",
  description: "A-Power steering return hose",
  column5: null,
  column6: null,
  column7: null,
  selected: 137,
  called: 127,
  percent: 92
 },
 {
  name: "53C3",
  date: "03/05/2018",
  b: "N",
  description: "A-Rear cross panel",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "55F7",
  date: "07/06/2010",
  b: "N",
  description: "A-Bonnet lock",
  column5: null,
  column6: null,
  column7: null,
  selected: 19,
  called: 19,
  percent: 100
 },
 {
  name: "55H5",
  date: "05/12/2014",
  b: "N",
  description: "A-Ball pin",
  column5: null,
  column6: null,
  column7: null,
  selected: 11902,
  called: 5301,
  percent: 44
 },
 {
  name: "57I1",
  date: "08/31/2021",
  b: "N",
  description: "A-Door Bowden cables replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 4395,
  called: 2289,
  percent: 52
 },
 {
  name: "58D7",
  date: "08/17/2019",
  b: "N",
  description: "A-Sealing right side panel",
  column5: null,
  column6: null,
  column7: null,
  selected: 2491,
  called: 2145,
  percent: 86
 },
 {
  name: "61B9",
  date: "12/20/2019",
  b: "N",
  description: "A-Hard top convertible roof flaps loos",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "64F8",
  date: "09/04/2020",
  b: "N",
  description: "A-Side windows loose",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "66L9",
  date: "03/16/2021",
  b: "N",
  description: "A-Spare wheel securing strap",
  column5: null,
  column6: null,
  column7: null,
  selected: 1863,
  called: 632,
  percent: 33
 },
 {
  name: "69D1",
  date: "07/10/2000",
  b: "N",
  description: "A-Earth cable of passenger airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 8,
  called: 1,
  percent: 12
 },
 {
  name: "69I3",
  date: "11/29/2011",
  b: "N",
  description: "A-Recoding of control units",
  column5: null,
  column6: null,
  column7: null,
  selected: 48,
  called: 46,
  percent: 95
 },
 {
  name: "69N7",
  date: "05/27/2016",
  b: "N",
  description: "A-Replace side airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 21,
  called: 21,
  percent: 100
 },
 {
  name: "69P4",
  date: "04/03/2017",
  b: "N",
  description: "A-Airbag igniter, seat belt with belt",
  column5: null,
  column6: null,
  column7: null,
  selected: 73,
  called: 69,
  percent: 94
 },
 {
  name: "69U2",
  date: "03/05/2018",
  b: "N",
  description: "A-Replace passenger airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "69X3",
  date: "01/31/2019",
  b: "N",
  description: "A-Gas generator of driver airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 38,
  called: 0,
  percent: 0
 },
 {
  name: "72C3",
  date: "11/09/2006",
  b: "N",
  description: "A-Bowden cable replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 13,
  called: 11,
  percent: 84
 },
 {
  name: "74D6",
  date: "03/18/2019",
  b: "N",
  description: "A-Front backrest padding",
  column5: null,
  column6: null,
  column7: null,
  selected: 13,
  called: 12,
  percent: 92
 },
 {
  name: "76A1",
  date: "06/30/2005",
  b: "N",
  description: "A-Leakage test of gas system",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "76A4",
  date: "07/03/2018",
  b: "N",
  description: "A-California gas system",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "97BM",
  date: "12/14/2015",
  b: "N",
  description: "A-Earth attachment",
  column5: null,
  column6: null,
  column7: null,
  selected: 2164,
  called: 1181,
  percent: 54
 },
 {
  name: "97DL",
  date: "07/18/2019",
  b: "N",
  description: "A-Wiring harness of electric seat",
  column5: null,
  column6: null,
  column7: null,
  selected: 15,
  called: 6,
  percent: 40
 },
 {
  name: "97L8",
  date: "06/25/2007",
  b: "N",
  description: "A-Central electrics unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 2,
  percent: 66
 },
 {
  name: "97R4",
  date: "06/21/2010",
  b: "N",
  description: "A-Leitungsstrang Behцrdenfahrzeuge",
  column5: null,
  column6: null,
  column7: null,
  selected: 23,
  called: 18,
  percent: 78
 },
 {
  name: "97W8",
  date: "02/24/2012",
  b: "N",
  description: "A-Cooling fan wiring loom",
  column5: null,
  column6: null,
  column7: null,
  selected: 254,
  called: 120,
  percent: 47
 },
 {
  name: "13C9",
  date: "02/10/2006",
  b: "C",
  description: "A-Dual-mass flywheel",
  column5: null,
  column6: null,
  column7: null,
  selected: 11,
  called: 10,
  percent: 90
 },
 {
  name: "15H4",
  date: "09/05/2019",
  b: "C",
  description: "A-Camshaft adjuster",
  column5: null,
  column6: null,
  column7: null,
  selected: 1242,
  called: 173,
  percent: 13
 },
 {
  name: "20L2",
  date: "12/13/2004",
  b: "C",
  description: "A-Tandem pump screws",
  column5: null,
  column6: null,
  column7: null,
  selected: 11,
  called: 11,
  percent: 100
 },
 {
  name: "20V7",
  date: "10/27/2014",
  b: "C",
  description: "A-Diesel fuel filter",
  column5: null,
  column6: null,
  column7: null,
  selected: 6,
  called: 4,
  percent: 66
 },
 {
  name: "23K5",
  date: "12/15/2011",
  b: "C",
  description: "A-High pressure pipe",
  column5: null,
  column6: null,
  column7: null,
  selected: 14,
  called: 14,
  percent: 100
 },
 {
  name: "37F2",
  date: "10/26/2009",
  b: "C",
  description: "A-CU direct shift gearbox",
  column5: null,
  column6: null,
  column7: null,
  selected: 55,
  called: 55,
  percent: 100
 },
 {
  name: "42I4",
  date: "11/28/2017",
  b: "C",
  description: "A-Rear wheel bearing housing",
  column5: null,
  column6: null,
  column7: null,
  selected: 7,
  called: 7,
  percent: 100
 },
 {
  name: "45F4",
  date: "06/28/2017",
  b: "C",
  description: "A-ABS/ESP control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 1974,
  called: 672,
  percent: 34
 },
 {
  name: "46I5",
  date: "12/23/2020",
  b: "C",
  description: "A-Checking brake pedal",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 3,
  percent: 100
 },
 {
  name: "50D8",
  date: "12/27/2017",
  b: "C",
  description: "A-Towing eye",
  column5: null,
  column6: null,
  column7: null,
  selected: 57895,
  called: 35226,
  percent: 60
 },
 {
  name: "58D2",
  date: "07/07/2016",
  b: "C",
  description: "A-Child lock",
  column5: null,
  column6: null,
  column7: null,
  selected: 2363,
  called: 2142,
  percent: 90
 },
 {
  name: "64F4",
  date: "09/12/2019",
  b: "C",
  description: "A-Rear side window",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "69CM",
  date: "09/15/2021",
  b: "C",
  description: "A-Airbagsteuergerдt",
  column5: null,
  column6: null,
  column7: null,
  selected: 112,
  called: 95,
  percent: 84
 },
 {
  name: "69M3",
  date: "04/08/2016",
  b: "C",
  description: "A-Side airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "69P7",
  date: "03/01/2017",
  b: "C",
  description: "A-Seat belt with belt tensioner",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "69R1",
  date: "08/10/2017",
  b: "C",
  description: "A-Replacing front seat belt",
  column5: null,
  column6: null,
  column7: null,
  selected: 1536,
  called: 1290,
  percent: 83
 },
 {
  name: "69V1",
  date: "02/12/2018",
  b: "C",
  description: "A-Passenger airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 9,
  called: 8,
  percent: 88
 },
 {
  name: "69X4",
  date: "02/15/2019",
  b: "C",
  description: "A-Gas generator of driver airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 66,
  called: 3,
  percent: 4
 },
 {
  name: "69Y9",
  date: "06/10/2019",
  b: "C",
  description: "A-Driver's side airbag",
  column5: null,
  column6: null,
  column7: null,
  selected: 24,
  called: 22,
  percent: 91
 },
 {
  name: "72I3",
  date: "12/20/2018",
  b: "C",
  description: "A-Check backrest of rear seat",
  column5: null,
  column6: null,
  column7: null,
  selected: 46,
  called: 44,
  percent: 95
 },
 {
  name: "72J1",
  date: "05/16/2019",
  b: "C",
  description: "A-Verification of the rear individual",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "91W6",
  date: "10/15/2019",
  b: "C",
  description: "A-ERA-GLONASS system",
  column5: null,
  column6: null,
  column7: null,
  selected: 718,
  called: 647,
  percent: 90
 },
 {
  name: "97BN",
  date: "12/23/2015",
  b: "C",
  description: "A-Supply for terminal 30_2",
  column5: null,
  column6: null,
  column7: null,
  selected: 614,
  called: 613,
  percent: 99
 },
 {
  name: "97DR",
  date: "02/14/2020",
  b: "C",
  description: "A-Body control module",
  column5: null,
  column6: null,
  column7: null,
  selected: 5739,
  called: 2110,
  percent: 36
 },
 {
  name: "06A2",
  date: "04/11/2012",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "09C4",
  date: "05/28/2020",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1079,
  called: 717,
  percent: 66
 },
 {
  name: "19O1",
  date: "12/06/2018",
  b: "V",
  description: "S-Austausch Kьhlflьssigkeit",
  column5: null,
  column6: null,
  column7: null,
  selected: 2420,
  called: 2034,
  percent: 84
 },
 {
  name: "19O6",
  date: "04/08/2019",
  b: "V",
  description: "S-Coolant expansion tank",
  column5: null,
  column6: null,
  column7: null,
  selected: 310,
  called: 304,
  percent: 98
 },
 {
  name: "20CM",
  date: "02/18/2021",
  b: "V",
  description: "S-Suction-jet pump",
  column5: null,
  column6: null,
  column7: null,
  selected: 548,
  called: 428,
  percent: 78
 },
 {
  name: "23AR",
  date: "10/12/2020",
  b: "V",
  description: "S-Engine control unit 1.2l",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 0,
  percent: 0
 },
 {
  name: "23BW",
  date: "07/06/2020",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1384,
  called: 1326,
  percent: 95
 },
 {
  name: "23U9",
  date: "02/15/2022",
  b: "V",
  description: "S-Engine control unit reprogramming",
  column5: null,
  column6: null,
  column7: null,
  selected: 731,
  called: 9,
  percent: 1
 },
 {
  name: "23X4",
  date: "10/01/2019",
  b: "V",
  description: "S-Engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 2,
  percent: 40
 },
 {
  name: "24GG",
  date: "10/07/2020",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 4,
  called: 3,
  percent: 75
 },
 {
  name: "24HT",
  date: "01/10/2022",
  b: "V",
  description: "S-Engine control unit update",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 3,
  percent: 100
 },
 {
  name: "34H7",
  date: "03/20/2017",
  b: "V",
  description: "S-Replace 6 speed gearbox",
  column5: null,
  column6: null,
  column7: null,
  selected: 18,
  called: 17,
  percent: 94
 },
 {
  name: "34K5",
  date: "06/04/2021",
  b: "V",
  description: "S-Reprogram gearbox control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 46,
  called: 16,
  percent: 34
 },
 {
  name: "34M8",
  date: "02/25/2022",
  b: "V",
  description: "S-Gearbox control unit update",
  column5: null,
  column6: null,
  column7: null,
  selected: 407,
  called: 65,
  percent: 15
 },
 {
  name: "37N1",
  date: "05/27/2021",
  b: "V",
  description: "S-Gearbox control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 141,
  called: 12,
  percent: 8
 },
 {
  name: "39D4",
  date: "03/30/2011",
  b: "V",
  description: "S-Kardanwelle Touareg 7P",
  column5: null,
  column6: null,
  column7: null,
  selected: 2323,
  called: 2312,
  percent: 99
 },
 {
  name: "40M3",
  date: "02/06/2018",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "40R1",
  date: "11/15/2021",
  b: "V",
  description: "S-Coupling rod",
  column5: null,
  column6: null,
  column7: null,
  selected: 1372,
  called: 807,
  percent: 58
 },
 {
  name: "44R2",
  date: "05/14/2021",
  b: "V",
  description: "S-Tyre pressure sensor",
  column5: null,
  column6: null,
  column7: null,
  selected: 148,
  called: 121,
  percent: 81
 },
 {
  name: "46G7",
  date: "05/26/2017",
  b: "V",
  description: "S-Parking brake control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 6219,
  called: 3460,
  percent: 55
 },
 {
  name: "50F5",
  date: "03/08/2021",
  b: "V",
  description: "S-Longitudinal member cover plate chec",
  column5: null,
  column6: null,
  column7: null,
  selected: 38,
  called: 31,
  percent: 81
 },
 {
  name: "51H8",
  date: "03/25/2022",
  b: "V",
  description: "S-Underbody repair",
  column5: null,
  column6: null,
  column7: null,
  selected: 88,
  called: 0,
  percent: 0
 },
 {
  name: "53D3",
  date: "03/31/2022",
  b: "V",
  description: "S-Stud repair",
  column5: null,
  column6: null,
  column7: null,
  selected: 12,
  called: 0,
  percent: 0
 },
 {
  name: "60C7",
  date: "08/08/2017",
  b: "V",
  description: "S-Sunroof frame water leak",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "60D8",
  date: "03/15/2019",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 1,
  percent: 50
 },
 {
  name: 6000,
  date: "11/18/2019",
  b: "V",
  description: "S-Drain of Panorama sun roof",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "66L4",
  date: "03/28/2020",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "90K8",
  date: "10/19/2018",
  b: "V",
  description: "S-Control unit for driver assist syste",
  column5: null,
  column6: null,
  column7: null,
  selected: 1152,
  called: 1122,
  percent: 97
 },
 {
  name: "91R6",
  date: "03/09/2018",
  b: "V",
  description: "S-Reprogram OCU control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 5,
  percent: 100
 },
 {
  name: "91W1",
  date: "11/22/2019",
  b: "V",
  description: "S-Private emergency call service",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "93B3",
  date: "05/02/2016",
  b: "V",
  description: "S-Service campaign, Golf GTE",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "93F9",
  date: "06/28/2021",
  b: "V",
  description: "S-Repair high-voltage battery",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 1,
  percent: 50
 },
 {
  name: "93H3",
  date: "03/19/2021",
  b: "V",
  description: "S-High-voltage charger sign",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 2,
  percent: 40
 },
 {
  name: "94N8",
  date: "06/14/2021",
  b: "V",
  description: "S-Headlight range control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 1035,
  called: 796,
  percent: 76
 },
 {
  name: "97AM",
  date: "05/07/2015",
  b: "V",
  description: "S-",
  column5: null,
  column6: null,
  column7: null,
  selected: 4,
  called: 1,
  percent: 25
 },
 {
  name: "97CP",
  date: "12/07/2017",
  b: "V",
  description: "S-Anlasserleitung Polo RUS",
  column5: null,
  column6: null,
  column7: null,
  selected: 30731,
  called: 23176,
  percent: 75
 },
 {
  name: "97DA",
  date: "04/27/2018",
  b: "V",
  description: "S-30A fuse",
  column5: null,
  column6: null,
  column7: null,
  selected: 1027,
  called: 622,
  percent: 60
 },
 {
  name: "97ET",
  date: "03/03/2021",
  b: "V",
  description: "S-ID.Software 2.1 software update",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: "06D1",
  date: "06/08/2021",
  b: "N",
  description: "S-Radio Infotainment instructions",
  column5: null,
  column6: null,
  column7: null,
  selected: 16,
  called: 6,
  percent: 37
 },
 {
  name: "09A7",
  date: "08/23/2017",
  b: "N",
  description: "S-Warning for third battery",
  column5: null,
  column6: null,
  column7: null,
  selected: 27,
  called: 17,
  percent: 62
 },
 {
  name: "13G6",
  date: "08/28/2017",
  b: "N",
  description: "S-Replace belt tensioning damper",
  column5: null,
  column6: null,
  column7: null,
  selected: 7663,
  called: 4985,
  percent: 65
 },
 {
  name: "15H5",
  date: "11/22/2019",
  b: "N",
  description: "S-Gates toothed belt",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "20AU",
  date: "03/20/2018",
  b: "N",
  description: "S-Diesel fuel filter",
  column5: null,
  column6: null,
  column7: null,
  selected: 371,
  called: 357,
  percent: 96
 },
 {
  name: "23DF",
  date: "06/01/2021",
  b: "N",
  description: "S-EGR correction",
  column5: null,
  column6: null,
  column7: null,
  selected: 1677,
  called: 538,
  percent: 32
 },
 {
  name: "23L8",
  date: "03/06/2013",
  b: "N",
  description: "S-Engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 3824,
  called: 3370,
  percent: 88
 },
 {
  name: "24HT",
  date: "01/10/2022",
  b: "N",
  description: "S-Engine control unit update",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 3,
  percent: 100
 },
 {
  name: "26L1",
  date: "09/05/2018",
  b: "N",
  description: "S-Replace EGR pipe",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "27H3",
  date: "11/11/2020",
  b: "N",
  description: "S-Starter motor",
  column5: null,
  column6: null,
  column7: null,
  selected: 47,
  called: 25,
  percent: 53
 },
 {
  name: "28H6",
  date: "04/11/2019",
  b: "N",
  description: "S-Impulse sender",
  column5: null,
  column6: null,
  column7: null,
  selected: 32,
  called: 29,
  percent: 90
 },
 {
  name: "34H7",
  date: "03/20/2017",
  b: "N",
  description: "S-Replace 6 speed gearbox",
  column5: null,
  column6: null,
  column7: null,
  selected: 18,
  called: 17,
  percent: 94
 },
 {
  name: "37H8",
  date: "01/24/2014",
  b: "N",
  description: "S-Mechatronik",
  column5: null,
  column6: null,
  column7: null,
  selected: 76,
  called: 72,
  percent: 94
 },
 {
  name: "37N3",
  date: "04/06/2021",
  b: "N",
  description: "S-Dual clutch gearbox selector lever c",
  column5: null,
  column6: null,
  column7: null,
  selected: 8,
  called: 6,
  percent: 75
 },
 {
  name: "40L9",
  date: "02/15/2017",
  b: "N",
  description: "S-Replace drive shafts",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "47O6",
  date: "02/06/2019",
  b: "N",
  description: "S-Brake line",
  column5: null,
  column6: null,
  column7: null,
  selected: 2,
  called: 2,
  percent: 100
 },
 {
  name: "47P2",
  date: "07/17/2019",
  b: "N",
  description: "S-Parking brake",
  column5: null,
  column6: null,
  column7: null,
  selected: 23,
  called: 23,
  percent: 100
 },
 {
  name: "69DK",
  date: "04/06/2022",
  b: "N",
  description: "S-Seatbelt status indicator",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 0,
  percent: 0
 },
 {
  name: "91BQ",
  date: "03/24/2021",
  b: "N",
  description: "S-California T6.1 pop-up roof",
  column5: null,
  column6: null,
  column7: null,
  selected: 5,
  called: 4,
  percent: 80
 },
 {
  name: "91U1",
  date: "10/25/2018",
  b: "N",
  description: "S-Cancel top speed restriction",
  column5: null,
  column6: null,
  column7: null,
  selected: 51,
  called: 49,
  percent: 96
 },
 {
  name: "97CL",
  date: "06/21/2017",
  b: "N",
  description: "S-Quality-improvement measures",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "97DJ",
  date: "05/20/2019",
  b: "N",
  description: "S-Earth stud of battery master switch",
  column5: null,
  column6: null,
  column7: null,
  selected: 1,
  called: 1,
  percent: 100
 },
 {
  name: "10H3",
  date: "02/18/2022",
  b: "C",
  description: "S-Schallschutzwanne",
  column5: null,
  column6: null,
  column7: null,
  selected: 30,
  called: 8,
  percent: 26
 },
 {
  name: "20CZ",
  date: "06/30/2021",
  b: "C",
  description: "S-Fuel tank sensor",
  column5: null,
  column6: null,
  column7: null,
  selected: 11,
  called: 10,
  percent: 90
 },
 {
  name: "23AX",
  date: "02/05/2020",
  b: "C",
  description: "S-Engine control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 6,
  called: 2,
  percent: 33
 },
 {
  name: "23CE",
  date: "07/06/2020",
  b: "C",
  description: "S-Fuel line",
  column5: null,
  column6: null,
  column7: null,
  selected: 1088,
  called: 1061,
  percent: 97
 },
 {
  name: "34F7",
  date: "03/05/2014",
  b: "C",
  description: "S-Gear oil replacement",
  column5: null,
  column6: null,
  column7: null,
  selected: 22980,
  called: 20640,
  percent: 89
 },
 {
  name: "34L1",
  date: "06/01/2021",
  b: "C",
  description: "S-Checking the tightening torque of th",
  column5: null,
  column6: null,
  column7: null,
  selected: 915,
  called: 780,
  percent: 85
 },
 {
  name: "37N4",
  date: "06/18/2021",
  b: "C",
  description: "S-Gearbox control unit",
  column5: null,
  column6: null,
  column7: null,
  selected: 199,
  called: 16,
  percent: 8
 },
 {
  name: "44R3",
  date: "06/15/2021",
  b: "C",
  description: "S-Tyre pressure sensor",
  column5: null,
  column6: null,
  column7: null,
  selected: 3,
  called: 3,
  percent: 100
 },
 {
  name: "50F8",
  date: "02/25/2022",
  b: "C",
  description: "S-Typschild",
  column5: null,
  column6: null,
  column7: null,
  selected: 1824,
  called: 89,
  percent: 4
 },
 {
  name: "69CD",
  date: "06/15/2021",
  b: "C",
  description: "S-Replacing side airbags",
  column5: null,
  column6: null,
  column7: null,
  selected: 54,
  called: 50,
  percent: 92
 },
 {
  name: 740,
  date: "04/02/2021",
  b: "C",
  description: "S-Bezug Sitzlehne",
  column5: null,
  column6: null,
  column7: null,
  selected: 372,
  called: 307,
  percent: 82
 },
 {
  name: "94K1",
  date: "02/07/2018",
  b: "C",
  description: "S-Headlights - adjusting",
  column5: null,
  column6: null,
  column7: null,
  selected: 1551,
  called: 1523,
  percent: 98
 },
 {
  name: "97EQ",
  date: "02/15/2021",
  b: "C",
  description: "S-Service packet Octavia IV",
  column5: null,
  column6: null,
  column7: null,
  selected: 3465,
  called: 3297,
  percent: 95
 }
]