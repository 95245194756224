export const Urls = {
  ACCOUNT: process.env.REACT_APP_ACCOUNT,
  APP_LINK_MOB: process.env.REACT_APP_LINK_MOB,
  APP_LINK_DESK: process.env.REACT_APP_LINK_DESK,
}

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://recall.vwgroup.ru' : 'https://api.recall-test.k8s-stage.vwgroup.dev';

export const Screens = {
  FORM: 'form',
  REQUIRED: 'required',
  NOT_REQUIRED: 'notRequired',
  SERVICE_DETAILS: 'serviceDetails',
};

export const PUBLIC_KEY = '6Ld_3qgeAAAAAKovl-mS825n8VynKlonYm6s8KGa';