import React from 'react';
import { Screens, Urls } from '../../const';
import {
	CheckIconSuccess,
	CheckIconWarning,
	IconLeftArrow,
} from '../icons/icons';

const screenData = {
	required: {
		icon: <CheckIconWarning className={'check-result__icon'} />,
		title: 'Tребуется проведение сервисного мероприятия',
		text: 'Ваш автомобиль попадает под действие сервисного мероприятия. Зарегистрируйтесь в\u00A0Личном кабинете или скачайте Мобильное приложение, чтобы узнать подробности и\u00A0записаться в\u00A0дилерский центр для проведения работ. Мы\u00A0на\u00A0постоянной основе отслеживаем качество продукции, повышаем надежность и\u00A0безопасность наших автомобилей.',
	},
	notRequired: {
		icon: <CheckIconSuccess className={'check-result__icon'} />,
		title: 'Не требуется проведение сервисного мероприятия',
		text: 'Ваш автомобиль не\u00A0попадает под действие сервисных мероприятий. Зарегистрируйтесь в\u00A0Личном кабинете или скачайте Мобильное приложение, чтобы отслеживать актуальность информации в\u00A0режиме онлайн. Мы\u00A0оповестим, если на\u00A0ваш автомобиль будет распространяться сервисное мероприятие.',
	},
};

const MOBILE_BREAKPOINT = 1025;
const { ACCOUNT, APP_LINK_MOB, APP_LINK_DESK } = Urls;

const CheckResult = ({ isRequired, setScreen }) => {
	const isMobile = window.matchMedia(
		`(max-width: ${MOBILE_BREAKPOINT}px)`
	).matches;
	const appLink = isMobile ? APP_LINK_MOB : APP_LINK_DESK;
	const clickHandler = (evt) => {
		evt.preventDefault();
		setScreen(Screens.FORM);
	};
	const data = isRequired ? screenData.required : screenData.notRequired;
	return (
		<div className="check-result">
			<button className="check-result__back" onClick={clickHandler}>
				<IconLeftArrow />
				Вернуться назад
			</button>
			{data.icon}
			<h1 className="check-result__title">{data.title}</h1>
			<p className="check-result__text">{data.text}</p>
			<div className="check-result__buttons">
				<a href={ACCOUNT} className="check-result__btn button" target="_parent">
					Зарегистрироваться
				</a>
				<a
					href={appLink}
					className="check-result__btn button button--transparent"
					target="_parent"
				>
					Скачать приложение
				</a>
			</div>
		</div>
	);
};

export default CheckResult;
