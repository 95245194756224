import React from 'react';

export const CheckIconSuccess = ({ className = '' }) => (
	<svg
		className={className}
		width={61}
		height={61}
		viewBox="0 0 61 61"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.333008 30.3334C0.333008 13.7648 13.7645 0.333374 30.333 0.333374C46.9015 0.333374 60.333 13.7648 60.333 30.3334C60.333 46.9019 46.9015 60.3334 30.333 60.3334C13.7645 60.3334 0.333008 46.9019 0.333008 30.3334ZM30.333 1.66671C14.5008 1.66671 1.66634 14.5012 1.66634 30.3334C1.66634 46.1655 14.5008 59 30.333 59C46.1652 59 58.9997 46.1655 58.9997 30.3334C58.9997 14.5012 46.1652 1.66671 30.333 1.66671ZM40.0803 23.2474L28.9585 39.235L21.4616 31.7381L22.4044 30.7953L28.7742 37.1651L38.9857 22.486L40.0803 23.2474Z"
			fill="#009900"
		/>
	</svg>
);

export const CheckIconWarning = ({ className = '' }) => (
	<svg
		className={className}
		width={61}
		height={61}
		viewBox="0 0 61 61"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.333008 30.3334C0.333008 13.7648 13.7645 0.333374 30.333 0.333374C46.9016 0.333374 60.333 13.7648 60.333 30.3334C60.333 46.9019 46.9016 60.3334 30.333 60.3334C13.7645 60.3334 0.333008 46.9019 0.333008 30.3334ZM30.333 1.66671C14.5008 1.66671 1.66634 14.5012 1.66634 30.3334C1.66634 46.1655 14.5008 59 30.333 59C46.1652 59 58.9997 46.1655 58.9997 30.3334C58.9997 14.5012 46.1652 1.66671 30.333 1.66671ZM29.6663 37.6667V41.6667H30.9997V37.6667H29.6663ZM30.9997 35V19H29.6663V35H30.9997Z"
			fill="#FFAA00"
		/>
	</svg>
);

export const IconLeftArrow = ({ className = '' }) => (
	<svg
		className={className}
		width={21}
		height={14}
		viewBox="0 0 21 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.003 6.53216H2.12563C3.09473 5.51506 4.78783 3.83346 6.89013 1.94996L6.00243 0.959961C5.94243 1.01366 0.00292969 6.34376 0.00292969 7.19726C0.00292969 8.07416 5.94293 13.4336 6.00243 13.4873L6.89013 12.4973C4.78523 10.612 3.07763 8.89656 2.10803 7.86246H20.003V6.53216Z"
			fill="#001E50"
		/>
	</svg>
);

export const Loader = ({ className = '' }) => (
	<svg className={`loader ${className}`} viewBox="25 25 50 50">
		<circle
			className={`loader__circle`}
			cx={50}
			cy={50}
			r={20}
			fill="none"
			stroke="white"
			strokeWidth={6}
		/>
	</svg>
);
