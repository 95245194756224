import React, { useEffect } from 'react';
import ServiceCheck from '../service-check/service-check';
import { loadReCaptcha } from 'recaptcha-v3-react-function-async';
import { PUBLIC_KEY } from '../../const';

function App() {
	useEffect(() => {
		loadReCaptcha(PUBLIC_KEY)
			.then(() => {
				console.log('ReCaptcha loaded');
			})
			.catch((e) => {
				console.error('Error when load ReCaptcha', e);
			});
	}, []);	
	return <ServiceCheck />;
}

export default App;
